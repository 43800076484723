import React from "react";
import { useTranslation } from 'react-i18next';

function News({ onClose }){
    const {t} = useTranslation();
    return(
        <>
            <section className="News">
                <h3>{t('menu-news')}</h3>
                <p>{t('news-p')}</p>
                <div className="social-link">
                    <a href="https://www.instagram.com/domaine_de_cagueloup_/">
                        <i className="fa-brands fa-instagram"></i>
                        <span>Instagram</span>
                    </a>
                    <a href="https://www.facebook.com/domaineducagueloup">
                        <i className="fa-brands fa-facebook"></i>
                        <span>facebook</span>
                    </a>
                </div>
                <div className="close-modal">
                    <button onClick={onClose}>
                        <h5>{t('button_close')}</h5>
                    </button>
                </div>
            </section>
            
        </>
    )
}

export default News