import React from "react";
import Maps from "../../component/maps/index.jsx";
import { useTranslation } from 'react-i18next';

function Contact({ onClose }) { 
    const {t} = useTranslation();
    return(
        <>
        <div className="contact">
            <h3>{t('contact_title')}</h3>
            <div className="contact-map-info">
                <div className="contact-info">
                    <div className="contact-multi-link">
                        <div className="contact-link mail">
                            <h4>{t('contact_title_mail')}</h4>
                            <a href="Email : domainedecagueloup@gmail.com"> domainedecagueloup@gmail.com</a>
                        </div>
                    
                        <div className="contact-link phone">
                            <h4>{t('contact_title_phone')}</h4>
                            <a href="tel:+334942615"> {t('contact_phone')}</a>
                        </div>
                    </div>
                    <div className="contact-adress">
                        <h4>{t('contact_title_adress')}</h4>
                        <p>
                        Domaine de Cagueloup<br></br>
                        267 Chemin de La Verdelaise<br></br>
                        83270 Saint-Cyr-Sur-Mer
                        </p>
                    </div>
                    <div className="contact_hours"> 
                        <div className="contact-link">
                            <h4>{t('contact_hours_high')}</h4>
                            <p>{t('contact_hours_high_info')}</p>
                        </div>
                        <div className="contact-link">
                            <h4>{t('contact_hours_low')}</h4>
                            <p>{t('contact_hours_low_info')}</p>
                        </div>
                    </div>
                </div>
                <Maps/>      
            </div>           
            <div className="close-modal">
                <button onClick={onClose}>
                    <h5>{t('button_close')}</h5>
                </button>
            </div>
        </div>
        </>
    )
}

export default Contact;




