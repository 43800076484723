import React from "react";
import { useTranslation } from 'react-i18next';

function History({ onClose }) {
    const {t} = useTranslation();
    return(
    <>
    <section className="history">
        <h3>{t('SevenHundredYears')}</h3>
        <article>
            <h4>{t('history-title1')}</h4>
            <p>
                {t('history-p1')}
            </p>
        </article>
        <article>
            <h4>{t('history-title2')}</h4>
            <p>
                {t('history-p2')}
            </p>
        </article>
        <article>
            <h4>{t('history-title3')}</h4>
            <p>
                {t('history-p3')}
            </p>
        </article>
        <article>
            <h4>{t('history-title4')}</h4>
            <p>
                {t('history-p4')}
            </p>
        </article>
       
        <div className="close-modal">
            <button onClick={onClose}>
                <h5>{t('button_close')}</h5>
            </button>
        </div>
    </section>
    </>
    )

}

export default History