import React, { useState } from "react";
import VideoPlayer from "../../component/videoplayer";
import scrollGif from "../../assets/video/scroll2.webm"; 
import image1 from "../../assets/gallerie/vue-mer2.webp";
import image2 from "../../assets/gallerie/grappe-seule.webp";
import image3 from "../../assets/gallerie/boutique.webp";
import image4 from "../../assets/gallerie/apero5.webp";
import Wine from "../Wine/index.jsx";
import History from "../History/index.jsx";
import Event from "../Event/index.jsx";
import News from "../News/index.jsx";
import Contact from "../Contact/index.jsx";
import Footer from "../Footer/index.jsx";


function Home({ t, isModalOpen, handleConfirmation, handleDenial }) {
   
    const [activeSection, setActiveSection] = useState('home');
    

    const handleModalOpen = (section) => {
        setActiveSection(section);
    };

    const handleModalClose = () => {
        setActiveSection('home');
    };

    const getTitleLinkStyle = (section) => {
        return {
            display: activeSection === section ? 'none' : 'block',
        };
    };
    

    return(
        <>
        {isModalOpen && (
            <div className="modal-majority">
                <div className="modal-content">
                    <p>{t('majority')}</p>
                    <button onClick={handleConfirmation}>{t('majority-yes')}</button>
                    <button onClick={handleDenial}>{t('majority-no')}</button>
                </div>
            </div>
        )}
        <div className={`container ${isModalOpen ? 'blurred' : ''}`}>
            <div className="container">     
                    <div id="wine" className="section video">
                        <div className="grid">
                            <div className="content">
                                <VideoPlayer/>
                                {activeSection === 'Wine' && (
                                    <div className="modal">
                                        <Wine onClose={handleModalClose} />
                                    </div> 
                                )}
                                {!isModalOpen && (
                                    <video autoPlay loop muted playsInline className="scroll-indicator">
                                       <source src={scrollGif} type="video/webm" alt="Scroll Down"/>
                                    </video>
                               )}

                            </div>
                            
                            <div className="title-link" style={getTitleLinkStyle('Wine')}>
                            {activeSection !== 'Wine' && (
                                <button onClick={() => handleModalOpen('Wine')}>
                                    <h2>{t('ExploreOurCellar')}</h2>
                                </button> 
                            )}
                            </div>       
                        </div>
                    </div>

                <div id="history" className="section image">
                    <div className="grid">
                        <div className="title-link" style={getTitleLinkStyle('History')}>
                        {activeSection !== 'History' && (
                            <button onClick={() => handleModalOpen('History')}>
                            <h2>{t('SevenHundredYears')}</h2>
                            </button> 
                         )}
                        </div>
                        <div className="content">
                            <img src={image2} alt="notre histoire"/>
                            {activeSection === 'History' && (
                            <div className="modal">
                                <History onClose={handleModalClose}  />
                            </div>
                        )}
                        {!isModalOpen && (
                                    <video autoPlay loop muted playsInline className="scroll-indicator">
                                       <source src={scrollGif} type="video/webm" alt="Scroll Down"/>
                                    </video>
                               )}
                        </div>          
                    </div>
                </div>

                <div id="event" className="section image">
                    <div className="grid">
                        <div className="title-link" style={getTitleLinkStyle('Event')}>
                        {activeSection !== 'Event' && (
                            <button onClick={() => handleModalOpen('Event')}>
                                <h2>{t('TastingInTheVineyards')}</h2>
                            </button> 
                            )} 
                        </div>
                        
                        <div className="content">
                            <img src={image4} alt="vue sur mer"/>
                            {activeSection === 'Event' && (
                            <div className="modal">
                                <Event onClose={handleModalClose}  />
                            </div>
                        )}
                        {!isModalOpen && (
                                    <video autoPlay loop muted playsInline className="scroll-indicator">
                                       <source src={scrollGif} type="video/webm" alt="Scroll Down"/>
                                    </video>
                               )}
                        </div>          
                    </div>
                </div>

                <div id="news" className="section image">
                    <div className="grid">
                        <div className="title-link" style={getTitleLinkStyle('News')}>
                        {activeSection !== 'News' && (
                        <button onClick={() => handleModalOpen('News')}>
                                <h2>{t('DomainNews')}</h2>
                            </button> 
                        )}
                        </div>
                        
                        <div className="content">
                            <img src={image1} alt="gamme"/>
                            {activeSection === 'News' && (
                            <div className="modal">
                                <News  onClose={handleModalClose}  />
                            </div>
                        )}
                        {!isModalOpen && (
                                    <video autoPlay loop muted playsInline className="scroll-indicator">
                                       <source src={scrollGif} type="video/webm" alt="Scroll Down"/>
                                    </video>
                               )}
                        </div>          
                    </div>
                </div>

                <div id="contact" className="section image">
                    <div className="grid">
                        <div className="title-link" style={getTitleLinkStyle('Contact')}>
                        {activeSection !== 'Contact' && (
                                <button onClick={() => handleModalOpen('Contact')}>
                                    <h2>{t('BookYourVisit')}</h2>
                                </button>
                            )}
                        </div>
                        <div className="content">
                            <img src={image3} alt="boutique"/>
                            {activeSection === 'Contact' && (
                                <div className="modal">
                                    <Contact  onClose={handleModalClose} />
                                </div>
                            )}
                            {!isModalOpen && (
                                    <video autoPlay loop muted playsInline className="scroll-indicator">
                                       <source src={scrollGif} type="video/webm" alt="Scroll Down"/>
                                    </video>
                               )}
                        </div>       
                    </div>
                </div>
                <div className="section last">
                    <Footer/>
                </div>
            </div>
        </div>
        </>
    )
}
export default Home;