import React, { useState, useEffect } from "react";
import Carrousel from "../../component/carroussel";
import { useTranslation } from "react-i18next";
import Info from "../../component/modales/info";

function Wine({ onClose }) {
  const { t } = useTranslation();
  const [selectedWine, setSelectedWine] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const handleSelectWine = (wine) => {
    setSelectedWine(wine);
    setShowInfo(true); 
  };

  const handleToggleInfo = () => {
    setShowInfo((prevShowInfo) => !prevShowInfo); 
  };

  useEffect(() => {
    setShowInfo(false);
  }, []);

  return (
    <div className="modal-wine">
    <div className="wine">
      <Carrousel onSelect={handleSelectWine} onToggleInfo={handleToggleInfo} showInfo={showInfo} />
      {showInfo && selectedWine && <Info wine={selectedWine} />}
    </div>
    <div className="close-modal">
            <button onClick={onClose}>
                <h5>{t('button_close')}</h5>
            </button>
        </div>
    </div>
  );
}

export default Wine;