import React, { useRef, useEffect } from 'react';
import video from '../../assets/video/15s1080p.mp4'; 

const VideoPlayer = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75;
    }
  }, []);

  return (
    <div className="video-player">
      <video ref={videoRef} autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
        Votre navigateur ne supporte pas la balise vidéo.
      </video>
    </div>
  );
}

export default VideoPlayer;
