import React from "react";
import { useTranslation } from 'react-i18next';

function Event({ onClose }){
    const {t} = useTranslation();
    return(
        <>
            <section className="Event">
                <h3>{t('event')}</h3>
                <div className="paragraphe">
                    <p>{t('event_p1')}</p>
                    <p>{t('event_p2')}</p>
                    <p>{t('event_p3')}</p>
                </div>
                <div className="close-modal">
                    <button onClick={onClose}>
                        <h5>{t('button_close')}</h5>
                    </button>
                </div>
            </section>
            
        </>
    )
}

export default Event