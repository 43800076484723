import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
    const {t} = useTranslation();
    return(
        <>
        <footer>
            <div className="footer">
                <p>
                    {t('legal-mention')}
                </p> 
                <a href="https://www.instagram.com/domaine_de_cagueloup_/">
                        <i className="fa-brands fa-instagram"></i>
                        <span>Instagram</span>
                    </a>
                    <a href="https://www.facebook.com/domaineducagueloup">
                        <i className="fa-brands fa-facebook"></i>
                        <span>facebook</span>
                    </a>
            </div>
        </footer>
        </>
    )
}

export default Footer;