import React, { useState, useEffect, useRef } from 'react';
import winesData from '../../data/data.json';
import MinetteBlanc from '../../assets/vins/Cuvee-Minette-blanc-2019.webp';
import MinetteRose from '../../assets/vins/Cuvee-Minette-rose-2019.webp';
import MinetteRouge from '../../assets/vins/Cuvee-Minette-Rouge-2021.webp';
import bandolBlanc from '../../assets/vins/bandol-blanc-2022.webp';
import bandolRouge from '../../assets/vins/bandol-rouge-2019.webp';
import bandolRose from '../../assets/vins/Bandol-rose-2019.webp';


const images = {
  'Cuvee-Minette-Rouge-2021.webp': MinetteRouge,
  'Cuvee-Minette-blanc-2019.webp': MinetteBlanc,
  'Cuvee-Minette-rose-2019.webp': MinetteRose,
  'bandol-blanc-2022.webp': bandolBlanc,
  'bandol-rouge-2019.webp': bandolRouge,
  'Bandol-rose-2019.webp': bandolRose,
  
};

function Carrousel({ onSelect, onToggleInfo, showInfo }) {
  const [start, setStart] = useState(0);
  const carrousselRef = useRef();

  const updateCentralWine = (newStart) => {
    const centralIndex = (newStart + 2) % winesData.length;
    onSelect(winesData[centralIndex]);
  };

  const handleScroll = (e) => {
    e.preventDefault();
    setStart((currentStart) => {
      let newStart;
      if (e.deltaY > 0) {
        newStart = (currentStart + 1) % winesData.length;
      } else {
        newStart = (currentStart - 1 + winesData.length) % winesData.length;
      }
      updateCentralWine(newStart);
      return newStart;
    });
  };

  useEffect(() => {
    const carrousselElement = carrousselRef.current;
    carrousselElement.addEventListener('wheel', handleScroll, { passive: false });
    return () => carrousselElement.removeEventListener('wheel', handleScroll);
  }, []);

  useEffect(() => {
    updateCentralWine(start);
  }, [start]);

  const circularWines = [...winesData, ...winesData.slice(0, 4)];
  const visibleWines = circularWines.slice(start, start + 5);

  const handleClick = (wine, index) => {
    const newStart = (start + index - 2 + winesData.length) % winesData.length;
    setStart(newStart);
    if (index === 2) {
      onToggleInfo(); 
    }
  };

  return (
    <div className={`slider ${showInfo ? 'reduced' : ''}`} ref={carrousselRef}>
      {visibleWines.map((wine, index) => (
        <img 
          key={index}
          src={images[wine.imageName]} 
          alt={wine.name}
          title="En savoir plus"
          onClick={() => handleClick(wine, index)}
          className={showInfo ? 'reduced' : ''}
        />
      ))}
    </div>
  );
}

export default Carrousel;
