import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo/cagueloup-logo.webp';

function Header(){
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
      };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return(
        <>
            <header>
                <div className="left-side">
                    <img className="logo" src={logo} alt={t('logo-alt')}></img>
                    <h1 className="header">
                        {t('home_title')}
                    </h1>
                </div>
                <div className="right-side">
                    <div className="button-lang">
                        {currentLanguage === 'en' && (
                            <button className="lang header" onClick={() => changeLanguage('fr')} aria-label={t('switch-language')}>
                                {t('key_fr')}
                            </button>
                        )}
                        {currentLanguage === 'fr' && (
                            <button className="lang header" onClick={() => changeLanguage('en')} aria-label={t('switch-language')}>
                                {t('key_en')}
                            </button>
                        )}
                    </div>
                    <div className="menu">
                        <button className="menu-svg" onClick={openModal} aria-label={t('menu-alt')}>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 32 32">
                                <path d="M 4 7 L 4 9 L 28 9 L 28 7 L 4 7 z M 4 15 L 4 17 L 28 17 L 28 15 L 4 15 z M 4 23 L 4 25 L 28 25 L 28 23 L 4 23 z"></path>
                            </svg>
                        </button>
                        
                        {isModalOpen && (
                        <div className="modal-menu">
                            <button className="menu-svg close" onClick={closeModal}  aria-label={t('close-menu-alt')}>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 52 52">
                                <path d="M 5 9 L 5 11 L 45 11 L 45 9 L 5 9 z M 5 24 L 5 26 L 45 26 L 45 24 L 5 24 z M 5 39 L 5 41 L 45 41 L 45 39 L 5 39 z"></path>
                            </svg>
                            </button>
                            <ul>
                                <li><a href="#wine">{t('menu-wine')}</a></li>
                                <li><a href="#history">{t('menu-history')}</a></li>
                                <li><a href="#event">{t('menu-event')}</a></li>
                                <li><a href="#news">{t('menu-news')}</a></li>
                                <li><a href="#contact">{t('menu-contact')}</a></li>
                            </ul>
                        </div>
                        )}
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;