import React from 'react';

const GoogleMap = () => {
  return (
    <div className='contact-map'>
      <iframe
        className='maps'
        title="Map of Domaine du Cagueloup"    
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2908.7015792161887!2d5.7168988673531675!3d43.194772117031974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9a7ef7f88e24f%3A0xf01c97990082b3f0!2sDomaine%20de%20Cagueloup!5e0!3m2!1sen!2sfr!4v1721292592523!5m2!1sen!2sfr"
        allowFullScreen=""
      ></iframe>
    </div>
  );
};

export default GoogleMap;